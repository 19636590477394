<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :range="true"
            label="결재요청일"
            defaultStart="-6M"
            defaultEnd="6M"
            name="period"
            v-model="period"
          />
        </div>
        {{  }}
      </template>
    </c-search-box>
    <c-table
      ref="table"
      :title="title"
      rowKey="sysApprovalRequestId"
      :columns="grid.columns"
      :data="grid.data"
      :isExcelDown="false"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'appr-todo-list',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        approvalDocType: '', // 결재함 유형
      }),
    },
  },
  data() {
    return {
      title: '',
      grid: {
        columns: [
          {
            name: 'approvalTypeName',
            field: 'approvalTypeName',
            label: '결재 업무명',
            align: 'center',
            sortable: true,
            style: 'width:200px',
          },
          {
            name: 'approvalKindName',
            field: 'approvalKindName',
            label: '결재종류',
            align: 'center',
            sortable: true,
            style: 'width:200px',
          },
          {
            name: 'approvalRequestName',
            field: 'approvalRequestName',
            label: '결재 요청명',
            align: 'left',
            sortable: true,
            type: 'link',
          },
          {
            name: 'reqDeptName',
            field: 'reqDeptName',
            label: '결재요청부서',
            align: 'center',
            sortable: true,
            style: 'width:120px',
          },
          {
            name: 'reqUserName',
            field: 'reqUserName',
            label: '결재요청자',
            align: 'center',
            sortable: true,
            style: 'width:120px',
          },
          {
            name: 'approvalStatusName',
            field: 'approvalStatusName',
            label: '결재상태',
            align: 'center',
            sortable: true,
            style: 'width:200px',
          },
          {
            name: 'requestDt',
            field: 'requestDt',
            label: '결재 요청일시',
            align: 'center',
            sortable: true,
            style: 'width:220px',
          },
        ],
        data: [],
      },
      searchParam: {
        startYmd: '',
        endYmd: '',
        approvalUserId: '',
        approvalKindCd: '',
        approvalStatusCd: 'ASC0000001', // 결재할문서
      },
      period: [],
      searchUrl: '',
      editable: true,
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.listUrl = selectConfig.sys.appr.list.todo.url;
      this.getList();
    },
    getList() {
      if (this.popupParam.approvalDocType) {
        this.getApprovalStatusCd();
        if (this.period && this.period.length > 0) {
          this.searchParam.startYmd = this.period[0];
          this.searchParam.endYmd = this.period[1];
        } else {
          this.searchParam.startYmd = '';
          this.searchParam.endYmd = '';
        }
        this.searchParam.approvalUserId = this.$store.getters.user.userId;
        this.$http.url = this.listUrl;
        this.$http.type = 'GET';
        this.$http.param = this.searchParam;
        this.$http.request((_result) => {
          this.grid.data = _result.data;
        },);
      }
    },
    linkClick(row) {
      this.popupOptions.title = '결재 상세'; // 행동관찰 상세
      this.popupOptions.param = {
        sysApprovalRequestId: row.sysApprovalRequestId,
        approvalDocType: this.popupParam.approvalDocType,
      };
      this.popupOptions.target = () => import(`${'./apprProcess.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    getApprovalStatusCd() {
      switch(this.popupParam.approvalDocType) {
        case 'TODO': // 결재할 문서
          this.searchParam.approvalStatusCd = 'ASC0000001';
          this.searchParam.approvalKindCd = '';
          this.title = '결재할 문서 목록';
          break;
        case 'REQ': // 결재요청 문서
          this.searchParam.approvalStatusCd = '';
          this.searchParam.approvalKindCd = 'AKC0000001';
          this.title = '결재요청 문서 목록';
          break;
        case 'APPR': // 결재한 문서
          this.searchParam.approvalStatusCd = 'ASC9999999';
          this.searchParam.approvalKindCd = '';
          this.title = '결재한 문서 목록';
          break;
        case 'PASS': // 회람함
          this.searchParam.approvalStatusCd = '';
          this.searchParam.approvalKindCd = 'AKC0000009';
          this.title = '회람함 목록';
          break;
      }
    }
  }
};
</script>
